@import '../../../../assets/styles/variables.scss';

.options-list {
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-top: 1px;
}

.items-list {
  padding-left: 7%;
  height: 40px;
  padding-top: 2%;
  border-bottom: 1px solid #ddd;
}

.items-list:hover {
  background-color: #f9f9f9;
}

.selected-option-container-priority {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
