@import '../../../assets/styles/variables.scss';

.alert-design-system {
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0 $alert-shadow;
  border: 0;
  padding-top: 8px;
  padding-left: 50px;
  padding-bottom: 8px;

  .ant-alert-message {
    font: $medium-s-font;
    color: $black;
  }

  .ant-alert-description {
    font: $regular-s-font;
    color: $black;
  }

  i {
    top: 8px;
  }

  .ant-alert-close-icon {
    i {
      color: $gray-1;
    }
  }

  &.ant-alert-with-description {
    padding-left: 60px;

    .ant-alert-icon {
      top: 4px;
    }
  }

  &.ant-alert-info {
    background-color: $lighter-blue-v2;
  }

  &.ant-alert-success {
    background-color: $active-row;
  }

  &.ant-alert-warning {
    background-color: $lighter-yellow;
  }

  &.ant-alert-error {
    background-color: $lighter-red;
  }
}
