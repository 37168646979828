@import '../../../../../assets/styles/variables.scss';

.export-pdf-print-date-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;

  &__date-picker {
    width: 200px;

    input {
      text-align: center;
    }
  }
}
