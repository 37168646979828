.shadow-container {
  box-shadow: 4px 8px 23px rgba(0, 0, 0, 0.05) !important;
}

.push-content-custom {
  position: relative;
  top: 65px;
}

.fixed-filter-header {
  position: fixed !important;
  width: 100% !important;
  background-color: white !important;
  z-index: 10000;
}

.lookahead-tables-fix {
  position: relative;
  top: 70px;
}

.style-for-fixed-header-titles {
  margin-bottom: 15px !important;
  margin-right: 70px !important;
  top: 182px !important;
  width: 100% !important;
  background-color: white !important;
  z-index: 10000;
  padding-right: 152px !important;
  position: fixed !important;
}

.custom-resize-col-lookahead {
  max-width: 1000px !important;
  max-height: 21px !important;
}

.loader-spinner-lookahead-header {
  display: flex !important;
  justify-content: center !important;
}

.loader-spinner-lookahead-table {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 65vh !important;
}

.fixed-tree-table-header {
  background-color: white !important;
  padding: 0 47px !important;
  max-height: 20px !important;
  overflow: hidden !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-resize-col-lookahead:hover {
  border-right: solid 1px #d8d8d8;
}

.massive-icon-style {
  margin-right: 23px;
  cursor: pointer;
}

.massive-selection-pop .ant-popover-inner-content {
  background: #586666;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.massive-selection-pop .ant-popover-inner {
  border-radius: 12px !important;
}

.massive-selection-pop .ant-popover-arrow {
  visibility: hidden !important;
}

.progress-massive-style {
  color: #ffffff;
}

.progress-massive-btn {
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 24px;
  background: var(--primary);
  border-radius: 5px;
  padding-top: 2px;
}

.progress-massive-style #rrp-baseDiv {
  position: relative !important;
  top: -17px !important;
  left: 39px !important;
}

.progress-massive-style .ant-calendar-picker-input.ant-input {
  background: #586666 !important;
  color: white;
}

.progress-massive-style .ant-calendar-picker-input.ant-input::placeholder {
  color: white !important;
}

.progress-massive-style .ant-calendar-picker-icon {
  color: white !important;
}

.progress-massive-style .ant-calendar-picker:hover .ant-calendar-picker-clear {
  background: #586666 !important;
}

.border-icon {
  text-shadow: 0 0 3px #00000080;
}

.custom-btn-for-focus-mode {
  background: #ff000000;
  border: none;
  cursor: pointer;
}

.custom-btn-for-focus-mode:focus {
  outline: none;
  box-shadow: none;
}

/**
 * Value wrapper
 */
.select-search-massive__value-massive {
  position: relative;
  z-index: 1;
}

.select-search-massive__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search-massive__input {
  cursor: pointer !important;
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #586666;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  color: white;
  -webkit-appearance: none;
}

.select-search-massive__input::placeholder {
  color: white;
}

.select-search-massive__input::-webkit-search-decoration,
.select-search-massive__input::-webkit-search-cancel-button,
.select-search-massive__input::-webkit-search-results-button,
.select-search-massive__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-massive__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search-massive__select {
  background: #586666;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search-massive__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search-massive__row:not(:first-child) {
  border-top: 1px solid #eee0;
}

/**
 * Option
 */
.select-search-massive__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #586666;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search-massive__option.is-selected {
  background: #444949;
  border-radius: 10px;
  color: #fff;
}

.select-search-massive__option.is-highlighted,
.select-search-massive__option:not(.is-selected):hover {
  background: #646969;
  border-radius: 10px;
}

.select-search-massive__option.is-highlighted.is-selected,
.select-search-massive__option.is-selected:hover {
  background: #444949;
  color: #fff;
}

.single-row-bottom-bordered:hover {
  background-color: #ededed3d !important;
}

/**
 * Group
 */
.select-search-massive__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search-massive.is-disabled {
  opacity: 0.5;
}

.select-search-massive.is-loading .select-search-massive__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search-massive:not(.is-disabled) .select-search-massive__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search-massive--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search-massive--multiple .select-search-massive__option {
  height: 33px;
}

.select-search-massive:not(.is-loading):not(.select-search-massive--multiple)
  .select-search-massive__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search-massive--multiple .select-search-massive__input {
  cursor: initial;
}

.select-search-massive--multiple .select-search-massive__input {
  border-radius: 3px 3px 0 0;
}

.select-search-massive--multiple:not(.select-search-massive--search)
  .select-search-massive__input {
  cursor: default;
}

.select-search-massive:not(.select-search-massive--multiple)
  .select-search-massive__input:hover {
  border-color: #2fcc8b;
}

.select-search-massive:not(.select-search-massive--multiple)
  .select-search-massive__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search-massive--multiple .select-search-massive__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #6f7b7b00;
  border-radius: 0 0 3px 3px;
}

.select-search-massive__options {
  padding: 0 !important;
}

.select-search-massive__select {
  max-height: 105px !important;
}

/**
 * Main wrapper
 */
.select-search-massive {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search-massive *,
.select-search-massive *::after,
.select-search-massive *::before {
  box-sizing: inherit;
}

.gantt-visualization .constraints-btn-gantt-view {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.gantt-visualization .text-btn-gantt-view {
  float: left;
  text-align: left;
  width: 95%;
}

/** open task card with the left button */
.gantt-visualization .text-btn-gantt-view-new {
  text-align: left;
  width: 95%;
}

.gantt_row_task:hover .open-card-button-new {
  opacity: 1;
}

.gantt_row_task .open-card-button-new {
  opacity: 0.01;
  cursor: pointer;
  background-image: url('../../../assets/img/newlookahead/name/open-card-gray.png');
  width: 16px;
  height: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 2px;
}

.gantt_row_task:hover .open-card-button-new:hover {
  background-image: url('../../../assets/img/newlookahead/name/open-card-orange.png');
}

.gantt-visualization .gantt_cell_tree {
  text-align: right !important;
}

.gantt-visualization .gantt_tree_indent {
  float: left !important;
}

.gantt-visualization .gantt_blank {
  float: left !important;
}

.gantt-visualization .gantt_tree_content {
  width: 100% !important;
}

.gantt-visualization .gantt_cell .fix_lean_status {
  /* width: 70% !important; */
  margin: auto;
}

.gantt-visualization .gantt_tree_icon {
  float: left !important;
}
.gantt-visualization .gantt_folder_open {
  float: left !important;
}

.delete-subcontract-lookahead {
  color: red !important;
  margin-left: 5px !important;
}

.subcontract-wrapper-lookahead {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.lookahead-filter-header .indicators-btn {
  display: inline-block;
}

.indicators {
  width: 525px;
  padding: 6px 8px;
  display: inline-flex;
  text-align: center;
  border: 1px solid #d1d1d1;
  -webkit-box-shadow: 1px 3px 5px -2px rgba(88, 102, 102, 0.75);
  -moz-box-shadow: 1px 3px 5px -2px rgba(88, 102, 102, 0.75);
  box-shadow: 1px 3px 5px -2px rgba(88, 102, 102, 0.75);
  border-radius: 5px;
  font-size: 12px;
  right: 180px;
  background: white;
  top: -20px;
  position: absolute;
}

.indicators-icon {
  display: inline-block;
  cursor: pointer;
}

.indicators .lean {
  padding: 0 11px;
  min-width: 90px;
}

.indicators .ant-switch-checked {
  background-color: #2c3421 !important;
}

.indicators .count-tasks {
  font-size: 16px;
  font-weight: bold;
}

.indicators .close {
  color: #333 !important;
  cursor: pointer;
  position: absolute;
  right: 7px;
}

.indicators-btn .hide {
  display: none !important;
}
.indicators-btn .hidden {
  visibility: hidden !important;
}

.indicators-btn .status-arr {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px !important;
}

.indicators-btn .status-arr .status-arr-item {
  padding: 0 10px;
}

.icon-massive-duration {
  position: relative;
  top: 3px;
}

.gantt-visualization .subcontract-border {
  padding: 0 8px !important;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  margin-top: 1px;
}

.gantt-visualization .col-subcontract-css {
  display: block;
  width: 100%;
}

.is_first-time__loader {
}

.is_first-time__gantt {
  opacity: 0;
  visibility: hidden;
}

/* Activity Tree pane in timeline */
.activity-pane__container {
  display: grid;
  grid-template-columns: minmax(364px, 1fr) 100%;
  height: calc(100vh - 222px) !important;
  overflow: hidden;
}

.toolbar-wrapper {
  height: 30px;
}
