@import '../../../assets/styles/variables.scss';

.textarea-design-system {
  display: flex;
  flex-direction: column;

  &__label {
    padding-bottom: 10px;
  }

  textarea {
    border-radius: 5px;
    padding: 10px;
    font: $regular-s-font;
    line-height: 1.2 !important;
    resize: none;
    border: 1px solid transparent;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

    &:hover:not(:focus) {
      border-color: transparent;
    }
  }

  &__counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    font: $regular-s-font;

    &.exceeded {
      font: $medium-s-font;
    }
    &.disabled {
      opacity: $disabled-opacity;
    }
  }

  &.theme--light {
    textarea {
      background-color: $cool-gray;
      color: $black;

      &:focus {
        border-color: $dark-mint;
      }
      &:disabled {
        background-color: $gray-1;
      }
    }

    &.error {
      textarea,
      textarea:hover {
        border-color: $red;
        background-color: $lighter-red;
      }
    }

    .textarea-design-system__counter {
      color: $dark-gray;

      &.at-limit {
        color: $orange;
      }
      &.exceeded {
        color: $red;
      }
    }
  }

  &.theme--dark {
    textarea {
      background-color: $dark-green;
      color: $white;

      &:focus {
        border-color: $primary;
      }
      &:disabled {
        background-color: $dark-green-sec;
      }
    }

    &.error {
      textarea,
      textarea:hover {
        border-color: $light-red;
        background-color: $deep-dark-red;
      }
    }

    .textarea-design-system__counter {
      color: $clear-bed-rock;

      &.at-limit {
        color: $light-orange;
      }
      &.exceeded {
        color: $light-red;
      }
    }
  }
}
