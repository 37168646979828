@import '../../../assets/styles/variables.scss';

.modal-design-system {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-body {
      padding: 0;

      .modal-design-system__title {
        width: 100%;
        height: 40px;
        background-color: $dark-green-sec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px 10px 0 0;
        padding: 0 20px;

        .title__content {
          display: flex;
          align-items: center;

          .content__icon {
            margin-right: 10px;

            i {
              img {
                width: 16px;
              }
            }
          }

          h2 {
            margin: 0;
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            color: $white;
          }
        }

        .title__close {
          .close__icon {
            cursor: pointer;

            i {
              img {
                width: 16px;
              }
            }
          }
        }
      }

      .modal-design-system__close {
        position: absolute;
        right: 14px;
        top: 14px;

        .close__icon {
          cursor: pointer;

          i {
            img {
              width: 16px;
            }
          }
        }
      }

      .modal-design-system__content {
        padding: 20px;

        &.padding {
          padding: 45px 20px 20px;
        }
      }
    }
  }

  &.theme--default {
    .ant-modal-content {
      background-color: $dark-green-sec;
    }
  }

  &.theme--dark {
    .ant-modal-content {
      background-color: $black;

      .ant-modal-body {
        padding: 0;

        .modal-design-system__title {
          background-color: $black;
        }
      }
    }
  }
}
