@import '../../assets/styles/variables.scss';

.option {
  padding: 10px 20px 10px 20px;
  border: 1px solid $dark-green;
  border-radius: 5px;
  gap: 10px;
  flex: 1;
  display: flex;

  span {
    color: #fff;
  }
  :global {
    label.ant-radio-wrapper {
      white-space: wrap !important;
      display: flex !important;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

div.optionsContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.container {
  display: flex;
  flex-direction: column;

  :global {
    .ant-checkbox-inner::after {
      border: 2px solid #000;
      border-top: 0;
      border-left: 0;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $dark-mint;
      border-radius: 100px;
    }

    .ant-checkbox-checked::after {
      border: unset;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  .form__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      color: $white;

      b {
        color: $primary;
        text-transform: uppercase;
      }
    }

    input {
      width: 200px;
      height: 32px;
      border-radius: 5px;
      border: 0;
      padding: 0 10px;
      background-color: $dark-green;
      color: $white;
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
    }
  }
}

.title {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h5 {
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: $white;
  }
}

.bottom {
  margin-top: 20px;
  display: flex;

  span {
    color: #fff;
  }
}

.buttons {
  display: flex;
  gap: 10px;
  button {
    height: 32px;
    border: 0;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    transition: 0.3s;

    &.buttons__cancel {
      background-color: $cool-gray;
      color: $black;
    }

    &.buttons__delete {
      background-color: $primary;
      color: $black;
      margin-left: 8px;

      &:disabled {
        opacity: $disabled-opacity;
        cursor: no-drop;
      }
    }
  }
}

.saveConfiguration {
  flex: 1;
  margin: auto;
}

.checkedOption {
  background: $dark-green;

  :global {
    .ant-radio-checked .ant-radio-inner::after {
      background-color: #53c255;
    }
  }
}

.acceptButton {
  background: $primary;
}
