@import '../../../assets/styles/variables.scss';

.switch-design-system {
  display: flex;
  align-items: center;
  gap: 10px;

  &__input {
    width: 30px !important;
    min-width: 30px !important;
    height: 20px !important;
    background-color: $gray-1 !important;

    &::after {
      width: 16px !important;
      height: 16px !important;
      background-color: $white;
      border-radius: 50%;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:focus-visible {
      outline: 1px solid $primary-70 !important;
    }

    &.ant-switch-checked {
      background-color: $dark-mint !important;
    }

    &.ant-switch-disabled {
      opacity: $disabled-opacity;
    }

    &.ant-switch-small {
      width: 24px !important;
      min-width: 24px !important;
      height: 16px !important;

      &::after {
        width: 12px !important;
        height: 12px !important;
      }
    }

    .ant-click-animating-node {
      opacity: 0;
    }
  }
}
