@import '../../../assets/styles/variables.scss';

.label-design-system {
  font: $regular-s-font;

  &.theme--light {
    color: $black;

    &.error {
      color: $red;
    }
  }

  &.theme--dark {
    color: $white;

    &.error {
      color: $light-red;
    }
  }
}
