@import '../../../assets/styles/variables.scss';

.shiftWarningModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.shiftWarningModal button {
  margin-top: 12px;
}

.shiftWarningModal-text {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shiftWarningModal-text p {
  font: $body-2-font;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}
