@import '../../../../assets/styles/variables.scss';

$dropdown-border-radius: 4px;
$dropdown-border-color: rgba(0, 0, 0, 0.09);
$dropdown-z-index: 9999;
$dropdown-min-width: 180px;

.custom-dropdown {
  .dropdown-trigger {
    cursor: pointer;
    user-select: none;
  }

  .dropdown-content {
    position: absolute;
    z-index: $dropdown-z-index;
    background-color: $white;
    box-shadow: $dropdown-shadow;
    border-radius: $dropdown-border-radius;
    border: 1px solid $dropdown-border-color;
    min-width: $dropdown-min-width;
    margin: 2px 0;

    a,
    button,
    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      font-family: $regular-s-font;
      font-size: $s-size-font;
      text-decoration: none;
      color: $black;
      cursor: pointer;
      text-align: left;
      width: 100%;
      height: 30px;
      background: none;
      border: none;
      font: $regular-s-font;
      white-space: nowrap;

      &:hover {
        background-color: $l-gray-2;
      }
    }

    button:hover {
      background-color: $white;
    }

    hr,
    .dropdown-divider {
      margin: 4px 0;
      border: none;
      border-top: 1px solid $dropdown-border-color;
    }
  }
}

.dropdown-search {
  border-bottom: 1px solid $l-gray-3;
  top: 0;
  background-color: $white;
  z-index: 1;
  width: 100%;
  padding: 0px 12px;
  display: flex;
  align-items: center;

  .search-icon {
    width: 14px;
    height: 14px;
    margin-left: 12px;
  }
}

.dropdown-search-input {
  height: 36px;
  border: 1px solid transparent;
  border-radius: 4px;
  font: $regular-s-font;
  outline: none;
  width: 100%;
}

.dropdown-search-input:focus {
  border-color: transparent;
}
