@import '../../../assets/styles/variables.scss';

.settings-plugins {
  min-height: 360px;

  &__cards {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }

  &__card {
    width: 210px;
    text-align: center;
    border-radius: 5px !important;
    box-shadow: 0px 0px 8px 0px $dark-black-1a;

    .button-design-system {
      width: 100%;
      padding: 0 10px;
    }
  }

  &__card-cover {
    padding: 20px 20px 0;
  }

  &__card-connected {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 15px;
  }
}
