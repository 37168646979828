@import '../../../../../assets/styles/variables.scss';
@import '../../../../DesignSystem/ButtonSystem/ButtonSystem.scss';

.autodesk-not-connected-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  &__icon {
    img {
      width: 48px;
      height: 48px;
    }
  }

  &__message {
    color: $white;
    font: $regular-m-font;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 0 20px;
  }

  &__link {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $primary;
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    gap: 10px;
  }

  &__footer-link {
    @extend .button-design-system;
    @extend .type--primary;
    @extend .size--default;

    &:hover,
    &:focus {
      color: $black;
      text-decoration: none;
      transition-property: all;
    }
  }
}
