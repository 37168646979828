@import '../../../../../assets/styles/variables.scss';

.container__submittals {
  @media (max-width: 1390px) {
    max-width: 60vw;
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-2;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: $black;
    border-radius: 3px;
  }
}

.submittals {
  width: 100%;
  padding: 0 20px;
  display: grid;
  grid-template-rows: 44px 160px;
  grid-template-columns: auto;

  .submittals__header {
    grid-row: 1/2;
    grid-column: 1/1;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns:
      minmax(70px, 0.8fr) minmax(40px, 0.5fr) minmax(160px, 1.8fr)
      minmax(90px, 1fr) minmax(70px, 0.8fr) minmax(75px, 0.85fr) minmax(
        75px,
        0.85fr
      )
      minmax(75px, 0.85fr) minmax(75px, 0.85fr) minmax(75px, 0.85fr) minmax(
        95px,
        1.1fr
      );
    justify-items: center;
    align-items: center;
    text-align: center;
    background-color: $cool-gray;
    border-radius: 5px 5px 0 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: $black;

    .header__content {
      width: 100%;
      grid-row: 1/3;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      position: relative;

      .header__line {
        position: absolute;
        width: 1px;
        height: 14px;
        background-color: $gray;
        right: 0;
      }
    }

    .header__number {
      grid-column: 1/2;
    }

    .header__rev {
      grid-column: 2/3;
    }

    .header__title {
      grid-column: 3/4;
    }

    .header__type {
      grid-column: 4/5;
    }

    .header__status {
      grid-column: 5/6;
    }

    .header__submittals-start-date,
    .header__submittals-start-date-planned,
    .header__submittals-start-date-actual,
    .header__submittals-approval-date,
    .header__submittals-approval-date-planned,
    .header__submittals-approval-date-actual,
    .header__rev,
    .herder__status,
    .header__title,
    .header__type {
      padding: 0 5px;
      height: 100%;
    }

    .header__submittals-start-date,
    .header__submittals-approval-date {
      align-items: flex-end;
    }

    .header__submittals-start-date-planned,
    .header__submittals-start-date-actual,
    .header__submittals-approval-date-planned,
    .header__submittals-approval-date-actual {
      align-items: flex-start;
    }

    .header__submittals-start-date {
      grid-row: 1/2;
      grid-column: 6/8;
    }

    .header__submittals-start-date-planned {
      grid-row: 2/3;
      grid-column: 6/7;
    }

    .header__submittals-start-date-actual {
      grid-row: 2/3;
      grid-column: 7/8;
    }

    .header__submittals-approval-date {
      grid-row: 1/2;
      grid-column: 8/10;
    }

    .header__submittals-approval-date-planned {
      grid-row: 2/3;
      grid-column: 8/9;
    }

    .header__submittals-approval-date-actual {
      grid-row: 2/3;
      grid-column: 9/10;
    }

    .header__lead-time {
      grid-column: 10/11;
    }

    .header__required-on-site-date {
      grid-column: 11/12;
    }
  }

  .submittals__body {
    grid-row: 2/3;
    grid-column: 1/1;
    border-radius: 0 0 5px 5px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 36px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-2;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: $black;
      border-radius: 3px;
    }

    .body__item {
      width: 100%;
      display: grid;
      grid-template-rows: 30px;
      grid-template-columns:
        minmax(70px, 0.8fr) minmax(40px, 0.5fr) minmax(160px, 1.8fr)
        minmax(90px, 1fr) minmax(70px, 0.8fr) minmax(75px, 0.85fr) minmax(
          75px,
          0.85fr
        )
        minmax(75px, 0.85fr) minmax(75px, 0.85fr) minmax(75px, 0.85fr) minmax(
          95px,
          1.1fr
        );

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        line-height: 14px;
        color: $white;
        border-bottom: 1px solid $bed-rock-border;
        padding: 0 10px;

        a {
          color: $white;
          text-decoration: underline;
        }

        &:nth-child(odd) {
          background-color: $bed-rock-t2;
        }

        &:nth-child(even) {
          background-color: $dark-green;
        }
      }
    }
  }
}
