@import '../../../../assets/styles/variables.scss';

.custom-companies-dropdown {
  span {
    display: block !important;
  }

  .item-gantt {
    height: 30px;
    width: 100%;
  }

  .company-border {
    width: 100% !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .create-companies-btn {
    left: -27px;
    position: relative;
    width: 120px;
    font-size: 12px;
    text-transform: none;
  }

  .remove-companies {
    &-x {
      position: relative;
      left: -5px;
    }

    &-text {
      color: $black;
      font-size: 12px;
      position: relative;
      text-transform: none !important;
    }
  }

  .remove-option-companies-custom {
    display: flex;
    position: relative;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    text-align: left;
  }

  .create-option,
  .remove-option {
    justify-content: center;
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
  }

  .options-list {
    height: 100%;
    max-height: 140px;
    overflow-y: auto;
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  .item-custom-selected-companies {
    display: inline !important;
  }

  .plus-icon {
    width: 10px;
    height: 10px;
    display: none;
  }

  .plus-icon-container {
    width: 10px;
    height: 10px;
  }

  .empty-selection {
    height: 33px;
    display: flex;
    padding-left: 5px;
    justify-content: flex-start;
    align-items: center;
  }

  .empty-selection:hover .plus-icon-container {
    background-image: url('../../../../assets/img/newlookahead/name/add.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.65;
  }

  .company-border {
    height: 100%;
    padding: 0;
  }

  .text-company {
    padding-left: 5px;
    padding-right: 5px;
    align-content: center;
    text-align: center;
  }

  .selected-option-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected-option {
    opacity: 0.7;
  }

  .selected-company-border {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .read-only {
    cursor: not-allowed;
  }

  .clickable {
    cursor: pointer;
  }
}
