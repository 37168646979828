.txtName {
  display: block !important;
  box-sizing: border-box !important;
  width: 40% !important;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0;
  border: 1px solid white !important;
  padding: 10px 0 !important;
  margin: auto !important;
  margin-bottom: 2px !important;
  font-size: 18px !important;
  top: 1px !important;
  border-bottom: 1px solid #2c3421 !important;
  text-align: center;
}

.frmCol {
  text-align: center;
}

.titleFrm {
  font-size: 35px;
  color: #2c3421;
}

.titleFrm div {
  display: inline-block;
  color: #ababab;
  font-size: 28px;
  margin-top: 7px;
  cursor: pointer;
}

.titleFrm div:first-child {
  float: left;
}

.titleFrm div:last-child {
  float: right;
}

.frmCalendar .frm {
  margin: 25px 0 40px;
  display: flex !important;
  min-height: 151px;
  align-items: center;
}

.footerBtns .btnSubmit,
.frmCalendar .btnSubmit,
.frmCalendar .ant-btn:hover,
.frmCalendar .ant-btn:focus,
.footerBtns .btnSubmit:hover,
.frmCalendar .btnSubmit:hover {
  height: auto !important;
  margin-top: 10px !important;
  background-color: var(--primary) !important;
  color: var(--black) !important;
  font-size: 16px !important;
  width: 110px !important;
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  border: transparent !important;
  padding: 4px 2px !important;
  cursor: pointer !important;
}

.frmCalendar .buttons {
  margin-top: 50px;
}

.footerBtns {
  text-align: center;
  display: flex;
  justify-content: end;
}

.footerBtns a {
  height: 30px;
  margin-top: 16px;
}

.frmCalendar .ant-modal-footer {
  padding: 0 20px 30px;
}

.frmCalendar .ant-modal-body {
  padding: 24px 24px 0 !important;
}

.editSubmit {
  margin-top: 0 !important;
}

.skipLink {
  margin-right: 20px !important;
  color: #2c3421 !important;
}

.wrappEdit .div-title {
  margin-top: 30px;
}

.calendarsEdit .inputInline-edit {
  width: 320px !important;
  min-width: 320px !important;
}

.table-items .edit-input input {
  border: 1px solid #f1f1f1;
}

.calendarsEdit .edit-title {
  position: relative;
  top: 3px;
  margin-left: 8px;
  color: #121212;
}

.footerBtns .disabled {
  cursor: not-allowed !important;
  background: #2c3421 !important;
  border: 1px solid #2c3421 !important;
}

.calendarsEdit .green {
  color: #53c255 !important;
}
