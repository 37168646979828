@import '../../assets/styles/variables.scss';

.export-pdf-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__tabs {
    margin: 0 -20px;
    height: 30px;
    background-color: $dark-green-sec;
    display: flex;
    padding: 0 20px;
  }

  &__tabs-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    line-height: 14px;
    color: $gray;
    cursor: pointer;
    transition-duration: 0.3s;
    font: $regular-s-font;

    &--active {
      background-color: $black;
      border-top: 3px solid $primary;

      &:hover {
        color: $gray;
      }
    }

    &:hover {
      color: $bed-rock;
    }
  }

  &__content {
    min-height: 430px;

    section {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    h5 {
      font: $medium-s-font;
      color: $white;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
