.listUsers .more-users {
  color: white;
  float: right;
  left: -9px;
  position: relative;
  top: 4px;
  font-size: 13px;
}

.imgLoadingHide {
  display: none;
}

.img-blur-project {
  filter: blur(10px);
  transition: 'filter 500ms ease';
}
